export default function showAds() {
  let showAd = () => {
    // console.log("show Ad preload run");
    window.adBreak({
      type: "browse",
      name: "browse",
    });
  };

  window.adConfig = function (o) {
    // console.log("inside ad config fn");
    window.adsbygoogle.push(o);
  };
  window.adBreak = function (o) {
    // console.log("inside adBreak fn");
    window.adsbygoogle.push(o);
  };
  window.adConfig({
    preloadAdBreaks: "on",
    onReady: showAd,
  });
}
