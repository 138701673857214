import React, { useEffect, useState } from "react";

function PopUp() {
  const [adLoaded, setAdLoaded] = useState(false);
  const [showad, setShowAd] = useState(true);
  const [insTagRendered, setInsTagRendered] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    script.async = true;
    // script.onload = () => {
    //   // Set adLoaded to true
    //   setTimeout(() => {
    //     setAdLoaded(true);
    //   }, [3000]);
    // };
    script.onerror = () => {
      // Handle error
    };
  
    document.body.appendChild(script);
    window.addEventListener("load", handleAdLoad);
    const adElement = document.createElement("ins");
    adElement.className = "adsbygoogle";
    adElement.style.display = "block";
    adElement.id = "popAdGoogle"
    adElement.setAttribute("data-ad-client", "ca-pub-5761017298734489");
    adElement.setAttribute("data-ad-slot", "5464835589");
    adElement.setAttribute("data-ad-format", "auto");
    adElement.setAttribute("data-ad-size", "auto");
    adElement.setAttribute("data-full-width-responsive", "true");
    
    setTimeout(() => {
        
        const popupContent = document.querySelector(".ub-popupcontent");
        
        if (popupContent) {
            console.log("ad added to popup")
            setAdLoaded(true)
            popupContent.appendChild(adElement);
          
            document.querySelector(".ub-popup-ad-container").style.display = "flex"
            
            console.log(document.querySelector(".ub-popup-ad-container"))
          } else {
            console.error("Container element not found.");
          } 
    },1000)
    return () => window.removeEventListener("load", handleAdLoad);
  }, []);

  const handleAdLoad = () => {
    setTimeout(() => {
      setAdLoaded(true);
    }, [2000]);
  };

  const handleClose = () => {
    setShowAd(false);
  };
  useEffect(() => {

    function checkAdvertisementStatus() {
      // Find the <ins> element with data-ad-slot="4080422259"
      const insElement = document.querySelector('ins[data-ad-slot="5464835589"]');
      
      // Check if the <ins> element exists and has the data-adsbygoogle-status attribute
      if (insElement ) {
        // Extract the value of the data-adsbygoogle-status attribute
        const adsbygoogleStatus = insElement.getAttribute('data-adsbygoogle-status');
        
        // Check the value of the attribute
        if (adsbygoogleStatus === 'done') {
          console.log('Advertisement is done loading.');
          
          // Clear the interval as the advertisement is loaded
          console.log("clear interval")
          clearInterval(intervalId);
        } else {
          console.log('Advertisement is still loading or has not been filled.');
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
      } else {
        console.log('The <ins> element with data-ad-slot="5464835589" was not found or does not have the data-adsbygoogle-status attribute.');
      }

    }
    const intervalId = setInterval(checkAdvertisementStatus, 1000);
  },[])
  

  
  
      
    
//   useEffect(() => {
//     if (adLoaded && !insTagRendered) {
//       (window.adsbygoogle = window.adsbygoogle || []).push({});
//       setInsTagRendered(true);
//     }
//     window.addEventListener("load", function () {
//       var adElement = document.querySelector(".adsbygoogle");
//       if (adElement) {
//         // Ad is rendered, do something
//         console.log("Ad is rendered");
//       }
//       var adElement = document.querySelector(
//         '.adsbygoogle[data-ad-client="ca-pub-5761017298734489"][data-ad-slot="5464835589"]'
//       );
//       if (adElement) {
//         console.log("popup ad is rendered");
//       }
//     });
//   }, [adLoaded, insTagRendered]);
  console.log("showad -  adloaded", showad, adLoaded);
  return showad &&    <>
      
        <div className="ub-popup-ad-container">
          <div className="ub-popupcontent">
            <div className="close_ub-popup" onClick={handleClose}>
              &#x78;
            </div>
            {/* <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-5761017298734489"
              data-ad-slot="5464835589"
              data-ad-format="auto"
              data-ad-size="auto"
              data-full-width-responsive="true"
            /> */}
          </div>
        </div>
      
    </>

}


export default PopUp;